<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-3 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="9"
        >
          <div class="form-group">
            <label>
              <strong>Search</strong>
            </label>
            <b-input
              v-model="tablePending.filter.search"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <div class="form-group">
            <label>
              <strong>Company</strong>
            </label>
            <b-select
              v-model="tablePending.filter.company"
              :options="filterCompanies"
            />
          </div>
        </b-col>
      </b-row>

      <b-table
        ref="tablePending"
        hover
        responsive
        class="mt-2"
        :per-page="tablePending.perPage"
        :current-page="tablePending.currentPage"
        :items="tableProvider"
        :fields="tablePending.fields"
        :sort-by.sync="tablePending.sortBy"
        :sort-desc.sync="tablePending.sortDesc"
        :sort-direction="tablePending.sortDirection"
        :filter="tablePending.filter"
        :filter-included-fields="tablePending.filterOn"
        show-empty
      >

        <template #cell(index)="data">
          {{ tablePending.currentPage * tablePending.perPage - tablePending.perPage + (data.index + 1) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              variant="primary"
              @click="onProcess(row.item)"
            >
              Process
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tablePending.perPage"
              :options="tablePending.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tablePending.currentPage"
            :total-rows="tablePending.totalRows"
            :per-page="tablePending.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>

    </b-card>

    <b-modal
      id="modal-maintenance-form"
      size="xl"
      scrollable
      no-close-on-backdrop
      no-enforce-focus
      aria-hidden="false"
      :title="modalTitle"
    >
      <ValidationObserver
        ref="formMaintenance"
      >
        <form
          novalidate
          role="form"
          @submit.prevent
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="created_at">
                  <strong>Requested At</strong>
                </label>
                <b-input
                  id="created_at"
                  v-model="maintenance.created_at"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="requestor_name">
                  <strong>Requested By</strong>
                </label>
                <b-input
                  id="requestor_name"
                  v-model="maintenance.requestor_name"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="company_name">
                  <strong>Company</strong>
                </label>
                <b-input
                  id="company_name"
                  v-model="maintenance.company_name"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="organization_name">
                  <strong>Organization</strong>
                </label>
                <b-input
                  id="organization_name"
                  v-model="maintenance.organization_name"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="estimated_amount">
                  <strong>Estimated Amount</strong>
                </label>
                <b-input
                  id="estimated_amount"
                  v-model="maintenance.estimated_amount"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="funding_type">
                  <strong>Funding Type</strong>
                </label>
                <b-input
                  id="funding_type"
                  v-model="fundingTypeValue"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="vehicle_license_plate">
                  <strong>Vehicle Plate Number</strong>
                </label>
                <b-input
                  id="vehicle_license_plate"
                  v-model="maintenance.vehicle_license_plate"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="vehicle_name">
                  <strong>Vehicle Name</strong>
                </label>
                <b-input
                  id="vehicle_name"
                  v-model="maintenance.vehicle_name"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="vehicle_category_name">
                  <strong>Vehicle Category</strong>
                </label>
                <b-input
                  id="vehicle_category_name"
                  v-model="maintenance.vehicle_category_name"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="vehicle_type">
                  <strong>Vehicle Type</strong>
                </label>
                <b-input
                  id="vehicle_type"
                  v-model="vehicleIsValue"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="noted_by_at">
                  <strong>Last Odometer</strong>
                </label>
                <b-input
                  id="noted_by_at"
                  v-model="maintenance.last_odometer"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="current_odometer">
                  <strong>Current Odometer</strong>
                </label>
                <b-input
                  id="current_odometer"
                  v-model="maintenance.current_odometer"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="damaged_item_specifications">
                  <strong>Ano ang Nasira?</strong>
                </label>
                <b-textarea
                  id="damaged_item_specifications"
                  v-model="maintenance.damaged_item_specifications"
                  rows="6"
                  max-rows="8"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="cause_of_damage">
                  <strong>Rason ng Pagkasira?</strong>
                </label>
                <b-textarea
                  id="cause_of_damage"
                  v-model="maintenance.cause_of_damage"
                  rows="6"
                  max-rows="8"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <label><strong>Attachments</strong></label>
              <b-table
                ref="attachment_table"
                class="mt-1"
                :fields="tableAttachments.fields"
                :items="maintenance.attachments"
                responsive
                striped
                hover
              >
                <template #cell(actions)="row">
                  <div class="text-nowrap">
                    <b-button
                      size="sm"
                      class="mr-1"
                      @click="onPreviewAttachment(row.item)"
                    >
                      Preview
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="noted_by_at">
                  <strong>Noted At</strong>
                </label>
                <b-input
                  id="noted_by_at"
                  v-model="maintenance.noted_by_at"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="noted_by_name">
                  <strong>Noted By</strong>
                </label>
                <b-input
                  id="noted_by_name"
                  v-model="maintenance.noted_by_name"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group>
                <label for="noted_by_remarks">
                  <strong>Noted By Remarks</strong>
                </label>
                <b-textarea
                  id="noted_by_remarks"
                  v-model="maintenance.noted_by_remarks"
                  rows="6"
                  max-rows="8"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <label><strong>Parte o Materyales na Kelangan</strong></label>
              <b-table
                ref="attachment_table"
                class="mt-1"
                :fields="tablePartsNeeded.fields"
                :items="maintenance.maintenance_items"
                responsive
                striped
                hover
              >
                <template #cell()="data">
                  <div class="text-nowrap">
                    {{ data.value }}
                  </div>
                </template>
              </b-table>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="approved_by_at">
                  <strong>Approved At</strong>
                </label>
                <b-input
                  id="approved_by_at"
                  v-model="maintenance.approved_by_at"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="approved_by_name">
                  <strong>Approved By</strong>
                </label>
                <b-input
                  id="approved_by_name"
                  v-model="maintenance.approved_by_name"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group>
                <label for="approved_by_remarks">
                  <strong>Approved By Remarks</strong>
                </label>
                <b-textarea
                  id="approved_by_remarks"
                  v-model="maintenance.approved_by_remarks"
                  rows="6"
                  max-rows="8"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="remarks"
                vid="purchased_by_remarks"
                rules="required"
              >
                <b-form-group>
                  <label for="purchased_by_remarks">
                    <strong>Remarks</strong>
                  </label>
                  <b-textarea
                    id="purchased_by_remarks"
                    v-model="maintenance.purchased_by_remarks"
                    rows="6"
                    max-rows="8"
                    placeholder="enter your remarks"
                    autocomplete="off"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
      <template #modal-footer="{ ok, cancel }">
        <div class="col-12 d-flex justify-content-between">
          <div>

            <b-overlay
              :show="state.busy"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block mr-1"
            >
              <b-button
                :disabled="state.busy"
                @click="onGenerateRepairPrintingForm()"
              >
                Print
              </b-button>
            </b-overlay>

            <b-button
              variant="success"
              class="mr-1"
              :disabled="state.busy"
              @click="onValidate()"
            >
              Approve
            </b-button>
            <b-button
              variant="outline-danger"
              :disabled="state.busy"
              @click="onValidate('Disapproved')"
            >
              Disapprove
            </b-button>
          </div>
          <b-button
            variant="outline-dark"
            :disabled="state.busy"
            @click="cancel()"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="modal-preview-image"
      size="lg"
      centered
      scrollable
      no-close-on-backdrop
      no-enforce-focus
      aria-hidden="false"
      :title="`Preview Attachment [${file.name}]`"
    >
      <div class="d-flex justify-content-center">
        <b-img
          fluid
          :src="file.url"
        />
      </div>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-dark"
          @click="cancel()"
        >
          Close Preview
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-preview-print"
      size="xl"
      centered
      scrollable
      no-close-on-backdrop
      no-enforce-focus
      aria-hidden="false"
    >
      <div style="height: 550px;">
        <iframe
          style="height: 100%; width: 100%"
          :src="pdfData"
        />
      </div>
      <div id="table-attachments">
        <table class="col-12 mt-5">
          <thead>
            <tr>
              <th class="h2">
                <div class="mb-5">
                  Attachments:
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="attachment in maintenance.attachments"
              :key="attachment.file_hash_name"
            >
              <td
                class="p-0 m-0"
              >
                <b-row class="mb-5">
                  <b-col
                    class="text-center"
                    cols="12"
                  >
                    <b-img
                      fluid
                      :src="attachment.url"
                      style="height: calc(100vh / 2.7); margin-bottom: 50px;"
                    />
                  </b-col>
                  <b-col
                    class="h3 text-center"
                    cols="12"
                  >
                    {{ attachment.file_original_name }}
                  </b-col>
                </b-row>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          v-print="'#table-attachments'"
        >
          Print Attachments
        </b-button>
        <b-button
          variant="outline-dark"
          @click="cancel()"
        >
          Close Preview
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
import { SPurchasingMaintenance, SSharedList, AxiosError } from '@/services'
import { core } from '@/config/pluginInit'
import formatter from '@/mixins/formatter'
import url from '@/mixins/url'
import helper from '@/mixins/helper'
import print from 'vue-print-nb'

export default {

  name: 'VPurchasingPending',

  middleware: ['auth', 'purchasing'],

  metaIno () {
    return {
      title: 'Pending Approvals'
    }
  },

  directives: {
    print
  },

  mixins: [formatter, url, helper],

  data () {
    return {
      state: {
        busy: false
      },
      file: {
        name: '',
        url: ''
      },
      list: {
        companies: []
      },
      maintenance: {
        document_number: '',
        id: 0,
        actual_amount: 0,
        estimated_amount: 0,
        funding_type: 'petty-cash',
        company_name: '',
        organization_name: '',
        position_name: 'Requester',
        requestor_name: '',
        user_name: '',
        vehicle_name: '',
        vehicle_category_name: '',
        vehicle_license_plate: '',
        vehicle_type: 'owned',
        damaged_item_specifications: '',
        cause_of_damage: '',
        last_odometer: 0,
        current_odometer: 0,
        status: 'Pending',
        noted_by_name: '',
        noted_by_position: 'Chief Mechanic',
        noted_by_remarks: '',
        noted_by_at: '',
        approved_by_name: '',
        approved_by_position: 'Logistics Manager',
        approved_by_remarks: '',
        approved_by_at: '',
        purchased_by_status: 'Disapproved',
        purchased_by_remarks: '',
        attachments: [],
        maintenance_items: [],
        created_at: ''
      },
      tablePending: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: '',
          company: 'All'
        },
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, label: 'requested at' },
          { key: 'document_number' },
          { key: 'requestor_name', label: 'Requested By' },
          { key: 'vehicle_license_plate', label: 'License Plate' },
          { key: 'vehicle_category_name', label: 'Vehicle Category' },
          { key: 'status' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, label: 'last updated' }
        ]
      },
      tableAttachments: {
        fields: [
          'actions',
          { key: 'file_original_name', label: 'filename' }
        ]
      },
      tablePartsNeeded: {
        fields: [
          { key: 'name' },
          { key: 'quantity' },
          { key: 'unit_of_measurement', label: 'UoM' },
          { key: 'item_from', formatter: this.findPartsFrom },
          { key: 'last_request', formatter: this.checkLastRequest }
        ]
      },
      pdfData: ''
    }
  },

  computed: {
    modalTitle () {
      return 'Process Request'
    },

    filterCompanies () {
      return [{ value: 'All', text: 'All' }].concat(this.list.companies)
    },

    fundingTypeValue () {
      return this.findFundingType(this.maintenance.funding_type)
    },

    vehicleIsValue () {
      return this.findVehicleIs(this.maintenance.vehicle_type)
    }
  },

  mounted () {
    core.index()
    this.getOptions()
  },

  methods: {

    async onGenerateRepairPrintingForm () {
      this.state.busy = true
      const pdfDocument = this.createPDFOptions({
        info: {
          title: 'Repair Form',
          author: '',
          creator: '',
          subject: ''
        },
        requestedBy: {
          id: this.maintenance.id,
          document_number: this.maintenance.document_number,
          organization_name: this.maintenance.organization_name,
          position_name: this.maintenance.position_name,
          name: this.maintenance.requestor_name,
          date: this.dateLongFormatter(this.maintenance.created_at),
          time: this.dateTimeLongFormatter(this.maintenance.created_at),
          vehicle_license_plate: this.maintenance.vehicle_license_plate,
          damaged_item_specifications: this.maintenance.damaged_item_specifications,
          cause_of_damage: this.maintenance.cause_of_damage,
          maintenance_items: this.maintenance.maintenance_items
        },
        notedBy: {
          position_name: this.maintenance.noted_by_position,
          name: this.maintenance.noted_by_name,
          remarks: this.maintenance.noted_by_remarks,
          time: this.dateTimeLongFormatter(this.maintenance.noted_by_at),
          organization_name: ' ',
          last_odometer: this.maintenance.last_odometer,
          current_odometer: this.maintenance.current_odometer
        },
        approvedBy: {
          position_name: this.maintenance.approved_by_position,
          name: this.maintenance.approved_by_name,
          remarks: this.maintenance.approved_by_remarks,
          time: this.dateTimeLongFormatter(this.maintenance.approved_by_at),
          organization_name: ' '
        },
        attachments: this.maintenance.attachments
      })

      pdfDocument.getDataUrl(dataUrl => {
        this.pdfData = dataUrl
        this.state.busy = false
      })

      this.$bvModal.show('modal-preview-print')
    },

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tablePending.busy = true
      const filters = this.objectToUrl({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_company: filter.company,
        filter_text: filter.search
      })

      return SPurchasingMaintenance.get(filters)
        .then(({ data }) => {
          this.tablePending.totalRows = data.total_rows
          return data.items.map(maintenance => {
            if (maintenance.attachments && maintenance.attachments.length > 0) {
              maintenance.attachments = maintenance.attachments.map(attachment => {
                attachment.url = this.getImageUrl(`${maintenance.attachments_path}/${attachment.file_hash_name}`)
                return attachment
              })
            }
            return maintenance
          })
        })
        .catch(() => {
          this.tablePending.totalRows = 0
          return []
        })
        .finally(() => {
          this.tablePending.busy = false
        })
    },

    async getOptions () {
      return await SSharedList.getCompanies().then(
        ({ data }) => {
          this.list.companies = data.map(
            ({ id, company_name }) => ({
              text: company_name,
              value: id
            })
          )
        }
      ).catch(() => {
        this.list.companies = []
      })
    },

    async onProcess (row) {
      this.maintenance.id = row.id
      this.maintenance.document_number = row.document_number
      this.maintenance.estimated_amount = row.estimated_amount
      this.maintenance.actual_amount = row.actual_amount
      this.maintenance.funding_type = row.funding_type
      this.maintenance.company_name = row.company_name
      this.maintenance.organization_name = row.organization_name
      this.maintenance.user_name = row.user_name
      this.maintenance.requestor_name = row.requestor_name
      this.maintenance.vehicle_name = row.vehicle_name
      this.maintenance.vehicle_category_name = row.vehicle_category_name
      this.maintenance.vehicle_license_plate = row.vehicle_license_plate
      this.maintenance.vehicle_type = row.vehicle_type
      this.maintenance.damaged_item_specifications = row.damaged_item_specifications
      this.maintenance.cause_of_damage = row.cause_of_damage
      this.maintenance.attachments_path = row.attachments_path
      this.maintenance.attachments = row.attachments
      this.maintenance.maintenance_items = row.maintenance_items
      this.maintenance.status = row.status
      this.maintenance.last_odometer = row.last_odometer
      this.maintenance.current_odometer = row.current_odometer
      this.maintenance.noted_by_name = row.noted_by_name
      this.maintenance.noted_by_position = row.noted_by_position
      this.maintenance.noted_by_remarks = row.noted_by_remarks
      this.maintenance.noted_by_at = row.noted_by_at
      this.maintenance.approved_by_name = row.approved_by_name
      this.maintenance.approved_by_position = row.approved_by_position
      this.maintenance.approved_by_remarks = row.approved_by_remarks
      this.maintenance.approved_by_at = row.approved_by_at
      this.maintenance.purchased_by_remarks = ''

      this.maintenance.created_at = this.dateTimeFormatter(row.created_at)

      this.$bvModal.show('modal-maintenance-form')
    },

    async onValidate (purchasedByStatus = 'Approved') {
      await this.$refs.formMaintenance.validate().then(
        async areAllFieldsValid => {
          if (areAllFieldsValid) {
            this.maintenance.purchased_by_status = purchasedByStatus
            const isApproved = purchasedByStatus === 'Approved'
            this.$swal.fire({
              icon: 'question',
              title: 'Confirmation',
              text: isApproved ? 'Approve this Repair/Maintenance Request?' : 'Disapprove this Repair/Maintenance Request?',
              confirmButtonColor: isApproved ? '#06C270' : '#FF2929',
              confirmButtonText: isApproved ? 'Approve' : 'Disapprove',
              cancelButtonColor: '#737373',
              showCancelButton: true,
              showLoaderOnConfirm: true,
              preConfirm: async () => {
                return this.onPut()
              },
              allowOutsideClick: () => !this.$swal.isLoading()
            })
          } else {
            this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            })
          }
        }
      )
    },

    async onPut () {
      this.state.busy = true
      return new Promise(resolve => {
        SPurchasingMaintenance.put(this.maintenance).then(
          ({ data }) => {
            this.$bvModal.hide('modal-maintenance-form')
            this.$swal.fire({
              icon: 'success',
              title: 'Successful',
              text: data.message,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            }).then(() => {
              this.$refs.tablePending.refresh()
            })
          }
        ).catch(
          error => {
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.formMaintenance.setErrors(error.message)
              }
            }
          }
        ).finally(resolve)
      }).then(() => {
        this.state.busy = false
      })
    },

    async onPreviewAttachment (file) {
      this.file.name = file.file_original_name
      this.file.url = file.url
      this.$bvModal.show('modal-preview-image')
    }
  }

}
</script>
